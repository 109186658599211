import { Flow } from "../../../types/Flow";
import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver, { IFlowAssignment } from "./IFlowResolver";

/**
 * An {@link IFlowResolver} which returns the flow matching the given name if it exists in the list of known
 * supported flows.
 */
class NameMatchFlowResolver implements IFlowResolver {
  private readonly _supportedFlows: Flow[];

  constructor(supportedFlows: Flow[]) {
    this._supportedFlows = supportedFlows;
  }

  readonly invoke = (_: IExperimentContextProps, sessionFlowName: string): IFlowAssignment => {
    const flow = this._supportedFlows.find(f => f.name === sessionFlowName);
    if (!flow) throw "Flow not found.";
    return { flow, experimentConfiguration: null };
  };
}

export default NameMatchFlowResolver;
