import { useCallback, useEffect, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import { LeadResult, LeadResultType } from "../../../../dtos/LeadResult";
import useObx from "../../../../utils/UseObx";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import OpsReservation from "../../../Types/OpsReservation";
import { IConfirmedAppointmentResponse } from "../Components/ConfirmationStepWrapper";
import OpsAppointmentWithDistance, { IOpsAppointmentWithDistanceRequest } from "../Types/OpsAppointmentWithDistance";
import { IUseConfirmAppointmentProps } from "./UseConfirmHybridAppointment";
import { IAppointmentResponse } from "./UseConfirmOpsAppointment";

/** The appointment confirmation request response. */
export interface IOpsAppointmentResponse extends IAppointmentResponse {
  /** Distance to Center in miles relative to the customer */
  distanceToCenterInMiles: Nullable<number>;
}

/** A hook which dispatches the appointment and lead result for creation when triggered and returns the response. */
const useConfirmOpsAppointmentWithDistance: TriggeredHook<
  IConfirmedAppointmentResponse,
  OpsReservation,
  boolean,
  IUseConfirmAppointmentProps
> = ({ appointmentNoteProps, leadId, customer, useCreateLeadResult }) => {
  const [result, setResult] = useState<Nullable<IConfirmedAppointmentResponse>>(null);

  const {
    res: appointmentResult,
    err: appointmentError,
    loading: appointmentLoading,
    setReq: setAppointmentReq
  } = useObx<IOpsAppointmentResponse, IOpsAppointmentWithDistanceRequest>("Post Appointment", { method: "post" });

  const {
    result: leadResult,
    isLoading: isLeadResultLoading,
    error: leadResultError,
    execute: triggerPostLeadResult
  } = useCreateLeadResult({ leadId });

  useEffect(() => {
    if (appointmentResult)
      triggerPostLeadResult(
        new LeadResult(
          appointmentResult.payload.customerId,
          customer.opportunity.id,
          LeadResultType.Booked,
          appointmentResult.payload.id
        )
      );
  }, [appointmentResult]);

  useEffect(() => {
    if (leadResult && appointmentResult)
      setResult({
        appointment: {
          id: appointmentResult.payload.id,
          startTimeUtc: appointmentResult.payload.startTimeUtc,
          endTimeUtc: appointmentResult.payload.endTimeUtc
        },
        leadResult
      });
  }, [leadResult, appointmentResult]);

  const execute = useCallback((opsReservation: OpsReservation) => {
    setAppointmentReq(
      q =>
        q && {
          ...q,
          url: ApiRequestPaths.postOpsAppointmentUrlV1_1(
            leadId,
            customer.id,
            opsReservation.center.id,
            opsReservation.slot.id
          ),
          payload: new OpsAppointmentWithDistance(
            opsReservation.center.id,
            customer.id,
            leadId,
            opsReservation.service,
            opsReservation.slot.startTimeUtc,
            opsReservation.slot.endTimeUtc,
            appointmentNoteProps,
            opsReservation.slot.id,
            opsReservation.center.distance
          )
        }
    );
  }, []);

  return {
    result,
    execute,
    isLoading: isLeadResultLoading || appointmentLoading,
    error: Boolean(leadResultError || appointmentError)
  };
};

export default useConfirmOpsAppointmentWithDistance;
