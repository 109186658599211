const configMissingExceptionMessage = (propName: string) =>
  `Failed to provide required configured value '${propName}'.`;
const configParseExceptionMessage = (propName: string) =>
  `Failed to parse configured value '${propName}' to the expected type.`;
const version = process.env.REACT_APP_VERSION_NO;
if (!version) throw configMissingExceptionMessage("REACT_APP_VERSION_NO");

const convertDotComAccountId = process.env.REACT_APP_CONVERT_DOT_COM_ACCOUNT_ID;
if (!convertDotComAccountId) throw configMissingExceptionMessage("REACT_APP_CONVERT_DOT_COM_ACCOUNT_ID");

const convertDotComProjectId = process.env.REACT_APP_CONVERT_DOT_COM_PROJECT_ID;
if (!convertDotComProjectId) throw configMissingExceptionMessage("REACT_APP_CONVERT_DOT_COM_PROJECT_ID");

const convertDotComDefaultVariationIdsStringified =
  process.env.REACT_APP_CONVERT_DOT_COM_DEFAULT_VARIATION_IDS?.toLowerCase();
if (!convertDotComDefaultVariationIdsStringified)
  throw configMissingExceptionMessage("REACT_APP_CONVERT_DOT_COM_DEFAULT_VARIATION_IDS");
const convertDotComDefaultVariationIds = JSON.parse(convertDotComDefaultVariationIdsStringified) as string[];
if (convertDotComDefaultVariationIds.constructor !== Array)
  throw configParseExceptionMessage("REACT_APP_CONVERT_DOT_COM_DEFAULT_VARIATION_IDS");

const baseUrl = process.env.REACT_APP_API_URL;
if (!baseUrl) throw configMissingExceptionMessage("REACT_APP_API_URL");

const fallbackCustomerDistanceToCenter = Number(process.env.REACT_APP_FALLBACK_CUSTOMER_DISTANCE_TO_CENTER);
if (isNaN(fallbackCustomerDistanceToCenter))
  throw configParseExceptionMessage("REACT_APP_FALLBACK_CUSTOMER_DISTANCE_TO_CENTER");

const opsSupportedCenterIdsStringified = process.env.REACT_APP_OPS_SUPPORTED_CENTER_IDS?.toLowerCase();
if (!opsSupportedCenterIdsStringified) throw configMissingExceptionMessage("REACT_APP_OPS_SUPPORTED_CENTER_IDS");
const opsSupportedCenterIds = JSON.parse(opsSupportedCenterIdsStringified) as string[];
if (opsSupportedCenterIds.constructor !== Array)
  throw configParseExceptionMessage("REACT_APP_OPS_SUPPORTED_CENTER_IDS");

const anonymousExperimentsFlag = process.env.REACT_APP_ANONYMOUS_EXPERIMENTS;
if (!anonymousExperimentsFlag) throw configMissingExceptionMessage("REACT_APP_ANONYMOUS_EXPERIMENTS");

const reservationLifespanSeconds = Number(process.env.REACT_APP_RESERVATION_LIFESPAN_SECONDS);
if (isNaN(reservationLifespanSeconds)) throw configParseExceptionMessage("REACT_APP_RESERVATION_LIFESPAN_SECONDS");

const calendarAllowedPaddingMinutes = Number(process.env.REACT_APP_CALENDAR_ALLOWED_PADDING_MINUTES);
if (isNaN(calendarAllowedPaddingMinutes))
  throw configParseExceptionMessage("REACT_APP_CALENDAR_ALLOWED_PADDING_MINUTES");

/** The environment configuration for the current  application instance.
 * @remarks GUID values are all cast to lower case.
 */
const EnvironmentConfiguration = {
  baseUrl,
  anonymousExperiments: anonymousExperimentsFlag === "true",
  convertDotCom: {
    accountId: convertDotComAccountId,
    projectId: convertDotComProjectId,
    defaultVariationIds: convertDotComDefaultVariationIds
  },
  optimizedPatientScheduler: {
    fallbackCustomerDistanceToCenter,
    supportedCenterIds: opsSupportedCenterIds
  },
  reservationLifespanSeconds,
  version,
  calendarAllowedPaddingMinutes
};

export default EnvironmentConfiguration;
