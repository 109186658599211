import { useCallback } from "react";
import { TriggeredHook } from "sonobello.utilities.react";
import { BearerTokenProvider } from "sonobello.utilities.react.axios";

import { Token } from "../../../dtos/Token";
import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";

/** A hook which sends a request to renew the provided token using its refresh secret and returns the resulting newly
 * issued token.
 */
const usePutToken: TriggeredHook<Token, Token> = () => {
  const { res, err, loading, setReq, setConf } = useObx<Token, Token>("Refresh Token", {
    url: ApiRequestPaths.putToken(),
    method: "put"
  });
  const execute = useCallback((token: Token) => {
    setConf(c => ({
      ...c,
      authProvider: new BearerTokenProvider(token.refresh, () => null, false)
    }));
    setReq(r => r && { ...r, payload: token });
  }, []);
  return {
    result: res?.payload || null,
    error: Boolean(err),
    isLoading: loading,
    execute
  };
};

export default usePutToken;
