import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { ActiveExperimentsHook } from "../Components/ExperimentContextLoader";

/** A hook which obtains the active experiments for the session from the Obx server using the manually provided request
 * auth.
 */
const useGetActiveExperimentsManualAuth: ActiveExperimentsHook = ({ bearerAuthToken: requestAuthorization }) => {
  const { res, err, loading } = useObx("activeExperiments", {
    url: ApiRequestPaths.getActiveExperiments(),
    config: { headers: { authorization: `Bearer ${requestAuthorization}` } }
  });
  return { result: res?.payload || null, isLoading: loading, error: err === undefined ? null : Boolean(err) };
};

export default useGetActiveExperimentsManualAuth;
