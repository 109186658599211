import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { IControlledMutator, Nullable, TriggeredHook } from "sonobello.utilities.react";

import { EndedSessionViewProps } from "../../../components/views/EndedSessionView";
import { Token } from "../../../dtos/Token";
import { IEndedSessionViewRouter } from "../../Routing/Types/IRouter";

export interface IAuthorizationRenewerProps extends IControlledMutator<Nullable<Token>> {
  /** The properties to be used when routing to the 'ended session' view. */
  endedSessionProps: EndedSessionViewProps;
  /** The router which can send the user to the 'ended session' view. */
  router: IEndedSessionViewRouter;
  /** The hook which will obtain a renewed token when triggered with an expired one. */
  usePutToken: TriggeredHook<Token, Token>;
}

/** Calls the token updater hook if provided with a token to renew, and reports its updated value. */
const AuthorizationRenewer: React.FC<IAuthorizationRenewerProps> = ({
  endedSessionProps,
  router,
  value,
  onChange,
  usePutToken
}) => {
  const { result, error, execute } = usePutToken();

  useEffect(() => {
    if (!value) return;
    if (DateTime.fromISO(value.refreshExpires, { zone: "UTC" }).toUTC() < DateTime.utc())
      router.goToEndedSessionView(endedSessionProps);
    else execute(value);
  }, [value]);

  useEffect(() => {
    if (result) onChange(result);
  }, [result]);

  useEffect(() => {
    if (error) router.goToEndedSessionView(endedSessionProps);
  }, [error]);

  return null;
};

export default AuthorizationRenewer;
