import { DispatchWithoutAction } from "react";

import { IExperimentConfiguration } from "./ExperimentConfiguration";
import ExperimentKey from "./ExperimentKey";

/** The properties available from the Experiment React context provider. */
interface IExperimentContextProps {
  /** Get the configuration for the given experiment by its key.
   * @throws If the experiment key is not found in the experiment configuration.
   */
  readonly getExperimentConfiguration: (experimentKey: ExperimentKey) => IExperimentConfiguration;

  /** Instructs the experiment service that the specified experiment resulted in a booked appointment. */
  readonly recordBookedAppointment: DispatchWithoutAction;
}

/** A stubbed {@link IExperimentConfiguration} that should show the experiment as inactive. */
export class DefaultExperimentConfiguration implements IExperimentConfiguration {
  readonly experimentId: string;
  readonly isActive: boolean;

  constructor() {
    this.experimentId = "";
    this.isActive = false;
  }
}

/** An experiment context which reports all experiments as 'inactive'. */
export class DefaultExperimentContextProps implements IExperimentContextProps {
  readonly getExperimentConfiguration = () => new DefaultExperimentConfiguration();
  readonly recordBookedAppointment = () => null;
}

export default IExperimentContextProps;
