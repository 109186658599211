import { Nullable } from "sonobello.utilities.react";

import { NewPatientFlow } from "../../../flows/NewPatientFlow";
import { NewPatientNoWelcomeFlow } from "../Compositions/NewPatientNoWelcomeFlow";
import { IExperimentConfiguration } from "./ExperimentConfiguration";
import ExperimentKey from "./ExperimentKey";
import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver, { IFlowAssignment } from "./IFlowResolver";

/**
 * An {@link IFlowResolver} which returns the experimental {@link NewPatientNoWelcomeFlow} if the
 * experiment context calls for it, otherwise the default assigned flow. */
class NewPatientNoWelcomeFlowResolver implements IFlowResolver {
  readonly invoke = (experimentContext: IExperimentContextProps, sessionFlowName: string): IFlowAssignment => {
    if (sessionFlowName === NewPatientNoWelcomeFlow.name || sessionFlowName === NewPatientFlow.name) {
      let experimentConfig: Nullable<IExperimentConfiguration> = null;
      try {
        experimentConfig = experimentContext.getExperimentConfiguration(ExperimentKey.NewPatientNoWelcomeStep);
      } catch (e) {
        console.error(e, e);
        console.warn(`Failed to retrieve feature flags for experiment ${ExperimentKey.NewPatientNoWelcomeStep}.`);
        return { flow: NewPatientFlow, experimentConfiguration: null };
      }
      return {
        flow:
          experimentConfig.isActive || sessionFlowName === NewPatientNoWelcomeFlow.name
            ? NewPatientNoWelcomeFlow
            : NewPatientFlow,
        experimentConfiguration: experimentConfig
      };
    }
    throw new Error(NewPatientNoWelcomeFlowResolver.Messages.flowNotResolved);
  };

  static readonly Messages = {
    flowNotResolved: "Flow name not supported."
  };
}

export default NewPatientNoWelcomeFlowResolver;
