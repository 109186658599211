import React, { useRef } from "react";
import { PropsWithChildren, useEffect } from "react";
import { IComponentParent, TriggeredHook } from "sonobello.utilities.react";

import { IAppContextProviderProps } from "../../../AppContext";
import { ICustomerExperimentAssignment } from "../Hooks/UseCreateCustomerExperiment";
import IExperimentContextProps from "../Types/IExperimentContextProps";
import IFlowResolver from "../Types/IFlowResolver";

export interface IExperimentAssignmentAppContextProviderProps extends Omit<IAppContextProviderProps, "flow"> {
  /** The bearer auth token used to authorize API requests outside of the App Context. */
  bearerAuthToken: string;
  /** The properties of the application's experiment context.  */
  experimentContextProps: IExperimentContextProps;
}

export type CreateCustomerExperimentHook = TriggeredHook<
  void,
  ICustomerExperimentAssignment,
  boolean,
  { leadId: string; bearerAuthToken: string }
>;

export interface IExperimentAssignmentAppContextProviderConfig {
  /** The list of resolvers to use to identify the flow experienced by the user. */
  flowResolver: IFlowResolver;
  /** The hook which dispatches customer experiment assignment if one is resolved. */
  useCreateCustomerExperiment: CreateCustomerExperimentHook;
  /** The application context which mounts and maintains the active application session. */
  AppContext: React.FC<IAppContextProviderProps & IComponentParent>;
}

/** Resolves the flow for the session and reports the experiment assignment if one is configured for it. */
export const ExperimentAssignmentAppContextProvider: React.FC<
  PropsWithChildren<IExperimentAssignmentAppContextProviderProps & IExperimentAssignmentAppContextProviderConfig>
> = ({
  bearerAuthToken,
  experimentContextProps,
  flowResolver,
  session,
  useCreateCustomerExperiment,
  AppContext,
  ...rest
}) => {
  const flowAssignmentRef = useRef(flowResolver.invoke(experimentContextProps, session.flowName));

  const { execute } = useCreateCustomerExperiment({ leadId: session.leadId, bearerAuthToken });

  useEffect(() => {
    if (flowAssignmentRef.current.experimentConfiguration)
      execute({
        customerId: session.customer.id,
        experimentId: flowAssignmentRef.current.experimentConfiguration.experimentId,
        isAssigned: flowAssignmentRef.current.experimentConfiguration.isActive
      });
  }, []);

  return <AppContext flow={flowAssignmentRef.current.flow} session={session} {...rest} />;
};

export default ExperimentAssignmentAppContextProvider;
