import { Nullable } from "sonobello.utilities.react";

import IExperimentContextProps from "./IExperimentContextProps";
import IFlowResolver, { IFlowAssignment } from "./IFlowResolver";

/**
 * A {@link IFlowResolver} which iterates through a configured list of child resolvers to determine the flow to be
 * experienced by the user.
 * */
class FailoverFlowResolver implements IFlowResolver {
  /** The resolvers which may assign the flow to the session. */
  private readonly flowResolvers: IFlowResolver[];

  /**
   * @param flowResolvers - The resolvers which may assign the flow to the session with order of priority descending.
   */
  constructor(flowResolvers: IFlowResolver[]) {
    this.flowResolvers = flowResolvers;
  }

  /** Resolves the flow assignment to be experienced. */
  public invoke = (experimentContextProps: IExperimentContextProps, sessionFlowName: string): IFlowAssignment => {
    let flowAssignment: Nullable<IFlowAssignment> = null;
    this.flowResolvers.find(flowResolver => {
      try {
        flowAssignment = flowResolver.invoke(experimentContextProps, sessionFlowName);
        return true;
      } catch {
        return false;
      }
    });
    if (!flowAssignment) throw new Error(FailoverFlowResolver.Messages.flowNotResolved);

    return flowAssignment;
  };

  public static Messages = {
    flowNotResolved: "No resolver was able to identify a flow to be used."
  };
}

export default FailoverFlowResolver;
