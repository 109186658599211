import ExperimentContextLoader, {
  ExperimentContextHook,
  IExperimentContextLoaderProps
} from "../Components/ExperimentContextLoader";
import { convertSDK } from "../Constants/ConvertSdk";
import useConvertDotCom from "../Hooks/UseConvertDotCom";
import useGetActiveExperimentsManualAuth from "../Hooks/UseGetActiveExperimentsManualAuth";
import useReportConnectionError from "../Hooks/UseReportConnectionError";

/** Bind the real convert sdk into the experience hook. */
const firstUseGetExperimentContextHook: ExperimentContextHook = props =>
  useConvertDotCom({ ...props, sdk: convertSDK });

/** A {@link ExperimentContextLoader} which supplies the experiment service connection hooks. */
const ObxExperimentContextLoader: React.FC<IExperimentContextLoaderProps> = props =>
  ExperimentContextLoader({
    ...props,
    useGetActiveExperiments: useGetActiveExperimentsManualAuth,
    useFirstUseGetExperimentContext: firstUseGetExperimentContextHook,
    useReportConnectionError: useReportConnectionError
  });

export default ObxExperimentContextLoader;
