import { ComponentProps } from "react";
import { IComponentParent } from "sonobello.utilities.react";

import { ProtectedAppContextProvider } from "../../../AppContext";
import SupportedFlows from "../../App/Compositions/SupportedFlows";
import ExperimentAssignmentAppContextProvider, {
  IExperimentAssignmentAppContextProviderProps
} from "../Components/ExperimentAssignmentAppContextProvider";
import useCreateCustomerExperimentManualAuth from "../Hooks/UseCreateCustomerExperiment";
import FailoverFlowResolver from "../Types/FailoverFlowResolver";
import NameMatchFlowResolver from "../Types/NameMatchFlowResolver";
import NewPatientNoWelcomeFlowResolver from "../Types/NewPatientNoWelcomeFlowResolver";
import ExperimentContextValueProvider from "./ExperimentContextValueProvider";

const configuredExperimentAssignmentAppContextProvider: React.FC<
  IExperimentAssignmentAppContextProviderProps
> = props =>
  ExperimentAssignmentAppContextProvider({
    ...props,
    flowResolver: new FailoverFlowResolver([
      new NewPatientNoWelcomeFlowResolver(),
      new NameMatchFlowResolver(SupportedFlows)
    ]),
    useCreateCustomerExperiment: useCreateCustomerExperimentManualAuth,
    AppContext: ProtectedAppContextProvider
  });

const ExperimentContextAppContextProvider: React.FC<
  IComponentParent & Omit<IExperimentAssignmentAppContextProviderProps, "experimentContextProps">
> = ExperimentContextValueProvider<
  Pick<IExperimentAssignmentAppContextProviderProps, "experimentContextProps">,
  ComponentProps<typeof configuredExperimentAssignmentAppContextProvider>
>({
  Component: configuredExperimentAssignmentAppContextProvider,
  map: experimentContextProps => ({ experimentContextProps })
});

export default ExperimentContextAppContextProvider;
