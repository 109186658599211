/** The properties which define the experiment's parameters.
 * @remarks This interface should be extended if additional parameters are needed for a specific experiment.
 */
export interface IExperimentConfiguration {
  /** Flag indicating if the experiment configuration should be experienced by the user instead of the default experience. */
  readonly isActive: boolean;
  /** The primary identifier of the experiment. */
  readonly experimentId: string;
}

/** @see {@link IExperimentConfiguration} */
class ExperimentConfiguration implements IExperimentConfiguration {
  readonly experimentId: string;
  readonly isActive: boolean;

  constructor(experimentId: string, isActive: boolean) {
    this.experimentId = experimentId;
    this.isActive = isActive;
  }
}

export default ExperimentConfiguration;
