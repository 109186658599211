import { BucketedVariation as ConvertBucketedVariation } from "@convertcom/js-sdk";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import ExperimentConfiguration from "./ExperimentConfiguration";

/** An {@link ExperimentConfiguration} for use with Convert.com experiments. */
class ConvertExperimentConfiguration extends ExperimentConfiguration {
  /**
   * Initializes a new instance of the {@link ConvertExperimentConfiguration} class.
   * @param experimentId - The primary identifier of the experiment.
   * @param variation - The Convert.com bucketed variation to configure the experiment with.
   * @remarks The experiment is active if the selected variations's external identifier is not within the configured
   * list of default variation identifiers.
   */
  constructor(experimentId: string, variation: ConvertBucketedVariation) {
    super(experimentId, !EnvironmentConfiguration.convertDotCom.defaultVariationIds.includes(variation.id));
  }
}

export default ConvertExperimentConfiguration;
