import { ContextInterface, ConvertInterface } from "@convertcom/js-sdk";
import { useEffect, useState } from "react";
import { Hook, Nullable } from "sonobello.utilities.react";

import { IExperimentContextHookProps } from "../Components/ExperimentContextLoader";
import ConvertExperimentContextProps from "../Types/ConvertExperimentContextProps";

export interface IUseConvertDotComExperienceConfig {
  /**
   * The Convert.com SDK which builds the {@link ContextInterface} which determines the experiments experienced by the
   * user and allows the results of the experiment to be recorded.
   */
  sdk: ConvertInterface;
}

/** Creates and maintains the Convert.com SDK connector in a stateful and observable manner. */
const useConvertDotCom: Hook<
  ConvertExperimentContextProps,
  boolean,
  IUseConvertDotComExperienceConfig & IExperimentContextHookProps
> = ({ activeExperiments, experimentIdentifier, sdk }) => {
  const [userContext, setUserContext] = useState<Nullable<ContextInterface>>(null);
  const [contextProps, setContextProps] = useState<Nullable<ConvertExperimentContextProps>>(null);
  const [error, setError] = useState<Nullable<boolean>>(null);

  useEffect(() => {
    async function initializeConvertSdk() {
      try {
        await sdk.onReady();
        setUserContext(sdk.createContext(experimentIdentifier, { country: "US", language: "en" }));
      } catch (e) {
        console.error("Failed to initialize Convert.com SDK.", e);
        setError(true);
      }
    }
    initializeConvertSdk();
  }, []);

  useEffect(() => {
    if (userContext && activeExperiments !== undefined) {
      setContextProps(new ConvertExperimentContextProps(userContext, activeExperiments || []));
    }
  }, [userContext, activeExperiments]);

  return { result: contextProps, isLoading: contextProps === null && error === null, error };
};

export default useConvertDotCom;
