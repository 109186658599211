import React, { useEffect, useState } from "react";
import { Hook, IComponentParent, Nullable, TriggeredHook, generateUuid } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../constants/EnvironmentConfiguration";
import { LocalStorageConfigs, loadFromLocalRaw } from "../../../utils/LocalStorage";
import LoadingExpander from "../../App/Components/LoadingExpander";
import IExperimentContextProps from "../Types/IExperimentContextProps";
import { ExperimentContextProvider } from "./ExperimentContext";

export interface IActiveExperiment {
  /** The application's primary identifier for the experiment. */
  id: string;
  /** The external identifier for the experiment in external experimentation platforms. */
  externalId: string;
}

export interface IExperimentContextLoaderProps extends IComponentParent {
  /** The unique value which identifies the application session to its external experimentation platforms. */
  experimentIdentifier: string;
  /** The string which authorizes requests to the OBX server. */
  bearerAuthToken: string;
}

export interface IExperimentContextHookProps {
  /** The list of active experiments for the session. */
  activeExperiments?: Nullable<IActiveExperiment[]>;
  /** The unique value which identifies the application session to its external experimentation platforms. */
  experimentIdentifier: string;
}

/** A hook which resolves the {@link IExperimentContextProps} for a given platform. */
export type ExperimentContextHook = Hook<IExperimentContextProps, boolean, IExperimentContextHookProps>;

export type ActiveExperimentsHook = Hook<IActiveExperiment[], boolean, { bearerAuthToken: string }>;

export interface IExperimentContextLoaderConfig {
  /** The hook which obtains the list of active experiments for the session. */
  useGetActiveExperiments: ActiveExperimentsHook;
  /** The first hook which obtains an experiment context using the session's lead id. */
  useFirstUseGetExperimentContext: ExperimentContextHook;
  /** The hook which triggers a connection error message. */
  useReportConnectionError: TriggeredHook<unknown, string>;
}

/** Awaits the successful or failed connection attempt to the external experiment services before loading the
 * experiment context and its children. If anonymous experiments are enabled, the anonymous experiment id is randomly
 * generated.
 * @remarks since hooks cannot be mounted conditionally, we cannot wrap them into an array or other collector and
 * instead must instantiate them individually from the props interface.
 */
const ExperimentContextLoader: React.FC<IExperimentContextLoaderProps & IExperimentContextLoaderConfig> = ({
  experimentIdentifier,
  children,
  bearerAuthToken,
  useGetActiveExperiments,
  useFirstUseGetExperimentContext,
  useReportConnectionError
}) => {
  const [resolvedExperimentIdentifier] = useState(() => {
    if (EnvironmentConfiguration.anonymousExperiments) {
      let anonymousExperimentId = loadFromLocalRaw(LocalStorageConfigs.anonymousExperimentId.key);
      if (!anonymousExperimentId) {
        anonymousExperimentId = generateUuid();
        localStorage.setItem(LocalStorageConfigs.anonymousExperimentId.key, anonymousExperimentId);
      }
      return anonymousExperimentId;
    } else return experimentIdentifier;
  });

  const { result: activeExperiments, error: activeExperimentsError } = useGetActiveExperiments({
    bearerAuthToken
  });

  const { result: firstResult, error: firstError } = useFirstUseGetExperimentContext({
    experimentIdentifier: resolvedExperimentIdentifier,
    activeExperiments: activeExperimentsError ? null : activeExperiments !== null ? activeExperiments : undefined
  });

  const { execute } = useReportConnectionError();

  useEffect(() => {
    if (firstError) execute("Convert.com");
  }, [firstError, execute]);

  const experimentContexts = firstResult ? [firstResult] : firstError ? [] : null;
  return experimentContexts ? (
    <ExperimentContextProvider experimentContexts={experimentContexts}>{children}</ExperimentContextProvider>
  ) : (
    <LoadingExpander />
  );
};

export default ExperimentContextLoader;
