import { ContextInterface as ConvertContextInterface } from "@convertcom/js-sdk";

import { IActiveExperiment } from "../Components/ExperimentContextLoader";
import ConvertContextInterfaceExtensions, {
  IConvertDotcomExperimentIdentity
} from "./ConvertContextInterfaceExtensions";
import ConvertExperimentConfiguration from "./ConvertExperimentConfiguration";
import ExperimentConfiguration, { IExperimentConfiguration } from "./ExperimentConfiguration";
import ExperimentKey from "./ExperimentKey";
import IExperimentContextProps, { DefaultExperimentConfiguration } from "./IExperimentContextProps";

/** The collection of goals that can be achieved by a Covert.com experiment. */
enum ConvertDotComGoals {
  /** The goal of booking a consultation at the end of a user experience. */
  BookedConsultation = "book-a-consultation"
}

/**
 * An {@link IExperimentContextProps} context for Convert.com.
 * @remarks Experiments are retrieved from Convert.com by their 'key', which is set by the system to be the hyphenated
 * name of the experiment.
 */
class ConvertExperimentContextProps implements IExperimentContextProps {
  /** The connection to the convert.com sdk contextualized for the current user. */
  private readonly userContext: ConvertContextInterface;
  /** The roster of live experiments that the user may experience. */
  private readonly experiments: Record<string, ExperimentConfiguration>;
  private readonly experimentIdentities: IConvertDotcomExperimentIdentity[];

  constructor(userContext: ConvertContextInterface, activeExperiments: IActiveExperiment[]) {
    try {
      this.experimentIdentities = ConvertContextInterfaceExtensions.getExperimentIdentities(
        userContext,
        activeExperiments
      );
    } catch (e) {
      console.warn("Failed to retrieve active experiments from Convert.com. Using default variations.", e);
      this.experimentIdentities = [];
    }
    this.experiments = {} as Record<string, ExperimentConfiguration>;
    this.userContext = userContext;
  }

  readonly getExperimentConfiguration = (experimentKey: ExperimentKey): IExperimentConfiguration => {
    if (this.experiments[experimentKey]) return this.experiments[experimentKey];
    const matchedExperimentIdentity = this.experimentIdentities.find(experienceIdentity =>
      experienceIdentity.experience.name.includes(experimentKey)
    );
    if (matchedExperimentIdentity) {
      try {
        this.experiments[experimentKey] = new ConvertExperimentConfiguration(
          matchedExperimentIdentity.id,
          this.userContext.runExperience(matchedExperimentIdentity.experience.key)
        );
      } catch (e) {
        console.error(e);
        console.warn(`Using default configuration for experiment '${experimentKey}'.`);
        this.experiments[experimentKey] = new DefaultExperimentConfiguration();
      }
    } else {
      console.warn(`Experiment '${experimentKey}' not found in Convert.com. Using default configuration.`);
      this.experiments[experimentKey] = new DefaultExperimentConfiguration();
    }
    return this.experiments[experimentKey];
  };

  readonly recordBookedAppointment = () => {
    try {
      this.userContext.trackConversion(ConvertDotComGoals.BookedConsultation);
    } catch (e) {
      console.error("Failed to record booked appointment conversion.", e);
    }
  };
}

export default ConvertExperimentContextProps;
