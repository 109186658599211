import { DateTime } from "luxon";
import { Nullable } from "sonobello.utilities.react";

import { ICalendarService } from "../../../Calendar/Types/ICalendar";
import { IAppointmentNoteProps } from "./IAppointment";
import OpsAppointment, { IOpsAppointmentRequest } from "./OpsAppointment";

/** An appointment request for the OPS booking process. */
export interface IOpsAppointmentWithDistanceRequest extends IOpsAppointmentRequest {
  /** The distance in miles from the customer to the center. */
  distanceToCenterInMiles: Nullable<number>;
}

/** {@inheritdoc IOpsAppointmentWithDistanceRequest} */
class OpsAppointmentWithDistance extends OpsAppointment implements IOpsAppointmentWithDistanceRequest {
  readonly distanceToCenterInMiles: Nullable<number>;

  constructor(
    centerId: string,
    customerId: string,
    leadId: string,
    service: ICalendarService,
    startTimeUtc: DateTime,
    endTimeUtc: DateTime,
    noteTagProps: IAppointmentNoteProps,
    slotId: number,
    distanceToCenterInMiles: Nullable<number>
  ) {
    super(centerId, customerId, leadId, service, startTimeUtc, endTimeUtc, noteTagProps, slotId);
    this.distanceToCenterInMiles = distanceToCenterInMiles;
  }
}

export default OpsAppointmentWithDistance;
