// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ContextInterface as ConvertContextInterface, Entity, EntityType } from "@convertcom/js-sdk";
import { Optional } from "sonobello.utilities.react";

import { IActiveExperiment } from "../Components/ExperimentContextLoader";

/** A custom type for the Convert.com {@link Entity} type since they are lacking a publicly accessible type spec.
 * @deprecated As of July 2024 there is now an official type library available at `@convertcom/js-sdk-types` which should
 * be used rather than developing this class further.
 */
export interface IConvertExperience {
  /** The primary identifier of the experience, this is a stringified integer. */
  id: string;
  /** The name of the experience as it appears in Convert.com. */
  name: string;
  /** The alternative identifier assigned to the experience by Convert.com.
   * @remarks This is the identifier most used by the `@convertcom/js-sdk` library to interact with the entity.
   */
  key: string;
}

export interface IConvertDotcomExperimentIdentity {
  /** The primary identifier of the experiment in the OBX system. */
  id: string;
  /** The experience entity received from Convert.com which is associated with the experiment. */
  experience: IConvertExperience;
}

/** Custom extensions for type safety when working with {@link ContextInterface} from `@convertcom/js-sdk`.
 * @deprecated As of July 2024 there is now an official type library available at `@convertcom/js-sdk-types` which should
 * be used rather than developing this class further.
 */
class ConvertContextInterfaceExtensions {
  /**
   * Get the {@link IConvertExperience} from the context interface for each known active experiment.
   * @param context - The context interface to retrieve the experiences from.
   * @param activeExperiments - The list of active experiments to retrieve the experiences for.
   */
  static readonly getExperimentIdentities = (
    context: ConvertContextInterface,
    activeExperiments: IActiveExperiment[]
  ): IConvertDotcomExperimentIdentity[] => {
    const experimentIdentities: IConvertDotcomExperimentIdentity[] = [];
    activeExperiments.forEach(({ id, externalId }) => {
      const experience = context.getConfigEntityById(externalId, EntityType.EXPERIENCE) as Optional<IConvertExperience>;
      if (experience) experimentIdentities.push({ id, experience });
    });
    return experimentIdentities;
  };
}

export default ConvertContextInterfaceExtensions;
