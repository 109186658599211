import { useCallback, useEffect, useRef, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import { HttpStatusCode } from "../../../../types/HttpStatusCodes";
import useObx from "../../../../utils/UseObx";
import CalendarSlotStatus from "../../../Calendar/Types/CalendarSlotStatus";
import { ICalendarService, IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import IReservation from "../../../Types/IReservation";
import LegacyReservation from "../../../Types/LegacyReservation";
import { IUseCreateReservationProps } from "./UseCreateHybridReservation";

export class ReservationRequest {
  /** The start time of the block in utc. */
  startTimeUtc: string;
  /** The end time of the block in utc. */
  endTimeUtc: string;
  /** The state of block being described by this object. */
  type: CalendarSlotStatus;

  constructor(slot: Pick<IScheduleSlot, "startTimeUtc" | "endTimeUtc">, calendarSlotStatus?: CalendarSlotStatus) {
    this.startTimeUtc = slot.startTimeUtc.toISO();
    this.endTimeUtc = slot.endTimeUtc.toISO();
    this.type = calendarSlotStatus ?? CalendarSlotStatus.Reserved;
  }
}

/** Handles the network requests necessary to make reservation requests for the legacy calendar process. */
const useCreateLegacyReservation: TriggeredHook<
  IReservation,
  IScheduleSlot,
  boolean,
  IUseCreateReservationProps
> = props => {
  const propsRef = useRef(props);
  const [result, setResult] = useState<Nullable<IReservation>>(null);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const { res, err, loading, setReq } = useObx<
    unknown,
    ReservationRequest,
    { service: ICalendarService; slot: IScheduleSlot }
  >("Post Reservation", { method: "post" });

  useEffect(() => {
    if (res?.code !== HttpStatusCode.Accepted) return;
    setResult(new LegacyReservation(propsRef.current.center, res.request.custom!.slot, res.request.custom!.service));
  }, [res]);

  const execute = useCallback(
    (slotToReserve: IScheduleSlot) =>
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postLegacyReservationUrl(
              propsRef.current.leadId,
              propsRef.current.center.id,
              propsRef.current.service.id
            ),
            payload: new ReservationRequest(slotToReserve),
            custom: {
              service: propsRef.current.service,
              slot: slotToReserve
            }
          }
      ),
    []
  );

  return {
    result,
    isLoading: loading,
    error: Boolean(err && err.error.response?.status !== HttpStatusCode.Unauthorized),
    execute
  };
};

export default useCreateLegacyReservation;
