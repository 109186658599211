import { useContext } from "react";
import { IContextValueProvider } from "sonobello.utilities.react";

import ExperimentContext from "../Components/ExperimentContext";
import IExperimentContextProps from "../Types/IExperimentContextProps";

/** A Value Provider composed from the {@link AppContextProps}. */
function ExperimentContextValueProvider<TFulfilledProps, TComponent extends TFulfilledProps>({
  Component,
  map
}: IContextValueProvider<IExperimentContextProps, TFulfilledProps, TComponent>): React.FC<
  Omit<TComponent, keyof TFulfilledProps>
> {
  return componentProps => {
    const contextProps = useContext(ExperimentContext);
    return Component({ ...componentProps, ...map(contextProps) } as unknown as TComponent);
  };
}

export default ExperimentContextValueProvider;
