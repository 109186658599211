import { useCallback, useEffect, useRef } from "react";

import { LocalStorageConfigs, loadFromLocal, saveToLocal } from "../../../utils/LocalStorage";
import useObx from "../../../utils/UseObx";
import ApiRequestPaths from "../../Constants/ApiRequestPaths";
import { CreateCustomerExperimentHook } from "../Components/ExperimentAssignmentAppContextProvider";

export interface ICustomerExperimentAssignment extends Record<string, unknown> {
  /** The id of the customer being assigned to the experiment. */
  customerId: string;
  /** The id of the experiment to which the customer is being assigned. */
  experimentId: string;
  /** A flag indicating if the customer is assigned to the associated experiment. */
  isAssigned: boolean;
}

/** A hook which reports customer registrations for experiments to the server.
 * @remarks Caches successful reports so they are never sent a second time.
 */
const useCreateCustomerExperimentManualAuth: CreateCustomerExperimentHook = ({ bearerAuthToken, leadId }) => {
  const reportedCustomerExperiments = useRef(
    loadFromLocal<ICustomerExperimentAssignment[]>(LocalStorageConfigs.reportedCustomerExperiments.key) || []
  );
  const { res, err, loading, setReq } = useObx<unknown, { isAssigned: boolean }, ICustomerExperimentAssignment>(
    "createCustomerExperiment",
    {
      method: "post",
      config: { headers: { authorization: `Bearer ${bearerAuthToken}` } }
    }
  );

  const execute = useCallback((customerExperimentAssignment: ICustomerExperimentAssignment) => {
    if (!reportedCustomerExperiments.current.some(exp => exp.customerId === customerExperimentAssignment.customerId))
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postCustomerExperiment(
              leadId,
              customerExperimentAssignment.customerId,
              customerExperimentAssignment.experimentId
            ),
            payload: { isAssigned: customerExperimentAssignment.isAssigned },
            custom: customerExperimentAssignment
          }
      );
  }, []);

  useEffect(() => {
    if (!res?.request.payload) return;
    reportedCustomerExperiments.current.push(res.request.custom!);
    saveToLocal(reportedCustomerExperiments.current, LocalStorageConfigs.reportedCustomerExperiments.key);
  }, [res]);

  return { result: null, error: err === undefined ? null : Boolean(err), isLoading: loading, execute };
};

export default useCreateCustomerExperimentManualAuth;
